import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cf3b92c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-secondary" }
const _hoisted_2 = { class: "navigation-secondary__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataBanksIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'banks' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.banks), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataPartnersRootIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'partners' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.partners), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataArticlesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'article' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.articles), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataPlaceOfCostsIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'place-of-costs' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.placeOfCosts), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataCountiesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'counties' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.counties), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataPlacesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'places' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.places), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataUnitOfMeasuresIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'unit-of-measures' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.unitOfMeasures), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataPaymentTypesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'payment-types' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.paymentTypes), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'AppAdministratorMasterDataInvoiceTypesIndexVue' },
            class: _normalizeClass({ 'router-link-exact-active': _ctx.exactRoute === 'invoice-types' })
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.invoiceTypes), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ])
  ]))
}