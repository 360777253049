// Store
import store from "@/store";

export interface AppPaymentTypeCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
  };
}

export class AppPaymentTypeCreateIndex implements AppPaymentTypeCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      title: "",
    };
  }
}