
// Vue
import {Options, Vue} from "vue-class-component";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({})
export default class AdministratorNavigationMasterDataVue extends Vue {
  exactRoute = "";

  get translation(): any {
    return getTranslation([
      "articles",
      "banks",
      "counties",
      "invoiceTypes",
      "partners",
      "paymentTypes",
      "placeOfCosts",
      "places",
      "unitOfMeasures",
    ]);
  }

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppAdministratorMasterDataRootIndexVue":
        this.exactRoute = "root";
        break;
      case "AppAdministratorMasterDataBanksIndexVue":
        this.exactRoute = "banks";
        break;
      case "AppAdministratorMasterDataPartnersRootIndexVue":
      case "AppAdministratorMasterDataPartnersDetailsIndexVue":
        this.exactRoute = "partners";
        break;
      case "AppAdministratorMasterDataArticlesIndexVue":
        this.exactRoute = "articles";
        break;
      case "AppAdministratorMasterDataPlaceOfCostsIndexVue":
        this.exactRoute = "place-of-costs";
        break;
      case "AppAdministratorMasterDataCountiesIndexVue":
        this.exactRoute = "counties";
        break;
      case "AppAdministratorMasterDataPlacesIndexVue":
        this.exactRoute = "places";
        break;
      case "AppAdministratorMasterDataUnitOfMeasuresIndexVue":
        this.exactRoute = "unit-of-measures";
        break;
      case "AppAdministratorMasterDataPaymentTypesIndexVue":
        this.exactRoute = "payment-types";
        break;
      case "AppAdministratorMasterDataInvoiceTypesIndexVue":
        this.exactRoute = "invoice-types";
        break;
    }
  }

  created(): void {
    this.setExactRoute();
  }
}
